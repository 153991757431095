import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionCard from '../accordion-card'

const AccordionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 4rem;
  padding-right: 4rem;
`

const AccordionTextContainer = styled(Box)`
  margin-top: 2rem;
`

const AccordionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.2rem',
      },
    }
  }}
  font-weight: bold;
  font-size: 1.4rem;
  color: #008174;
  padding-left: 16px;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)``

export default function AccordionFeature() {
  return (
    <AccordionContainer>
      <AccordionTextContainer>
        <AccordionLabelTypography>
          Child protection policy
        </AccordionLabelTypography>
      </AccordionTextContainer>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>1. Application</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography sx={{ lineHeight: '2.1' }}>
            The National Child Protection Policy (Policy) will apply to Tri Tag
            Rugby and its Licensees.
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            The Policy applies to all Tri Tag Rugby Participants; coaches;
            officials; spectators; volunteers Licensees and administrators and
            all people reasonably connected to Tri Tag Rugby (Participants).
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <AccordionCard
        label="2. Policy Statement"
        description={[
          'Tri Tag Rugby and its Licensees are committed to the safety and wellbeing of all children and young people involved in Tri Tag Rugby.',
          'The rights of children are fundamental. Tri Tag Rugby supports the active participation of children in Tri Tag Rugby.',
          'This Policy provides Tri Tag Rugby Participants guidance to ensure a safe environment for all children in Tri Tag Rugby and to assist with obligations under child protection laws.',
        ]}
      />
      <AccordionCard
        label="3. What Is Child Abuse "
        description={[
          'Tri Tag Rugby and its Licensees are committed to the safety and wellbeing of all children and young people involved in Tri Tag Rugby.',
          'The rights of children are fundamental. Tri Tag Rugby supports the active participation of children in Tri Tag Rugby.',
          'This Policy provides Tri Tag Rugby Participants guidance to ensure a safe environment for all children in Tri Tag Rugby and to assist with obligations under child protection laws.',
        ]}
      />
      <AccordionCard
        label="4. Reporting Child Abuse"
        description={[
          'If Tri Tag Rugby or its Participants suspect or believe that a child needs protection, they should report it immediately to the police or the relevant government agency.',
          'Tri Tag Rugby and its Participants must comply with all applicable mandatory reporting laws. Mandatory reporting is a term used to describe the legislative requirement imposed on selected classes of people to report suspected cases of child abuse and neglect to government authorities. All Australian states and territories have enacted mandatory reporting laws. However, the laws are not the same across all jurisdictions. ',
          'Tri Tag Rugby and its Participants must comply with all relevant child protection laws when dealing with a complaint or incidence of child abuse. In particular: ',
          'Tri Tag Rugby and its Participants should deal with all allegations of child abuse promptly and confidentially and should not disclose information about the child, the accused or details of the complaint except as required by law.',
          'Tri Tag Rugby and its Participants should not attempt to investigate, mediate or conduct any hearing into any allegation of child abuse. ',
        ]}
      />
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            5. Working with Children: Statutory Obligations
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography sx={{ lineHeight: '2.1' }}>
            Tri Tag Rugby and its Participants acknowledge that selecting
            suitable and appropriate people to work with children is vital. Tri
            Tag Rugby and its Licensees should conduct reference checks as far
            as practicable in relation to Participants who are required to work
            with children.
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            All Participants who are required to work with children must comply
            with the working with children obligations in their state or
            territory. This may involve a Participant undertaking a “working
            with children check” to determine their suitability to work in a
            paid or volunteer capacity with children.
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            Information about statutory working with children obligations can be
            found in the URLs below:
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>QLD</b>: www.bluecard.qld.gov.au
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>NSW</b>: www.check.kids.nsw.gov.au
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>WA</b>: www.checkwwc.wa.gov.au
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>VIC</b>: www.justice.vic.gov.au/workingwithchildren
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>SA</b>: www.families.sa.gov.au/pages/protectingchildren
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>TAS</b>: www.police.tas.gov.au
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>NT</b>: www.workingwithchildren.nt.gov.au
          </Typography>
          <Typography sx={{ lineHeight: '2.1' }}>
            <b>ACT</b>:
            www.ors.act.gov.au/community/working_with_vulnerable_people_wwvp
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <AccordionCard
        label="6. Taking Images of Children"
        description={[
          'Tri Tag Rugby and its Participants should inform the parent/guardian about the nature of the use of the image(s) as well as how the image(s) will be stored and how the image(s) can be accessed by the parent/guardian. ',
          'Tri Tag Rugby and its Participants should obtain permission from a child’s parent/guardian before using an image of a child. The image(s) should be sufficiently connected to Tri Tag Rugby and the child should be suitably clothed in a manner that promotes Tri Tag Rugby. ',
          'Tri Tag Rugby and its Participants respect the privacy of other Participants and must not use camera phones, cameras, video cameras or any other type of recording or photo taking device inside changing areas, showers and toilets.',
        ]}
      />
    </AccordionContainer>
  )
}
